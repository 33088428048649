/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { ContactRead } from "../models/contact-read";
import { createContact } from "../fn/contacts/create-contact";
import { CreateContact$Params } from "../fn/contacts/create-contact";
import { deleteContact } from "../fn/contacts/delete-contact";
import { DeleteContact$Params } from "../fn/contacts/delete-contact";
import { readContact } from "../fn/contacts/read-contact";
import { ReadContact$Params } from "../fn/contacts/read-contact";
import { readContacts } from "../fn/contacts/read-contacts";
import { ReadContacts$Params } from "../fn/contacts/read-contacts";
import { Status } from "../models/status";
import { updateContact } from "../fn/contacts/update-contact";
import { UpdateContact$Params } from "../fn/contacts/update-contact";

@Injectable({ providedIn: "root" })
export class ContactsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readContacts()` */
  static readonly ReadContactsPath = "/surfdomeinen/organisations/{organisation_id}/contacts/";

  /**
   * Read Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  readContacts$Response(
    params: ReadContacts$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ContactRead>>> {
    return readContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readContacts(params: ReadContacts$Params, context?: HttpContext): Observable<Array<ContactRead>> {
    return this.readContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactRead>>): Array<ContactRead> => r.body),
    );
  }

  /** Path part for operation `createContact()` */
  static readonly CreateContactPath = "/surfdomeinen/organisations/{organisation_id}/contacts/";

  /**
   * Create Contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContact$Response(
    params: CreateContact$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ContactRead | Status>> {
    return createContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContact(params: CreateContact$Params, context?: HttpContext): Observable<ContactRead | Status> {
    return this.createContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactRead | Status>): ContactRead | Status => r.body),
    );
  }

  /** Path part for operation `readContact()` */
  static readonly ReadContactPath = "/surfdomeinen/organisations/{organisation_id}/contacts/{contact_id}";

  /**
   * Read Contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  readContact$Response(params: ReadContact$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactRead>> {
    return readContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readContact(params: ReadContact$Params, context?: HttpContext): Observable<ContactRead> {
    return this.readContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactRead>): ContactRead => r.body),
    );
  }

  /** Path part for operation `deleteContact()` */
  static readonly DeleteContactPath = "/surfdomeinen/organisations/{organisation_id}/contacts/{contact_id}";

  /**
   * Delete Contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContact$Response(params: DeleteContact$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return deleteContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteContact(params: DeleteContact$Params, context?: HttpContext): Observable<Status> {
    return this.deleteContact$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }

  /** Path part for operation `updateContact()` */
  static readonly UpdateContactPath = "/surfdomeinen/organisations/{organisation_id}/contacts/{contact_id}";

  /**
   * Update Contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContact$Response(
    params: UpdateContact$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ContactRead | Status>> {
    return updateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContact(params: UpdateContact$Params, context?: HttpContext): Observable<ContactRead | Status> {
    return this.updateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactRead | Status>): ContactRead | Status => r.body),
    );
  }
}

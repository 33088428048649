/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { Status } from "../models/status";
import { validateRecord } from "../fn/validate/validate-record";
import { ValidateRecord$Params } from "../fn/validate/validate-record";

@Injectable({ providedIn: "root" })
export class ValidateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `validateRecord()` */
  static readonly ValidateRecordPath = "/surfdomeinen/validate/record";

  /**
   * Validate Record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateRecord$Response(
    params: ValidateRecord$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Status>> {
    return validateRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Validate Record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateRecord(params: ValidateRecord$Params, context?: HttpContext): Observable<Status> {
    return this.validateRecord$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }
}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { changeReverseZone } from "../fn/reverse/change-reverse-zone";
import { ChangeReverseZone$Params } from "../fn/reverse/change-reverse-zone";
import { createReverseZone } from "../fn/reverse/create-reverse-zone";
import { CreateReverseZone$Params } from "../fn/reverse/create-reverse-zone";
import { deleteReverseZone } from "../fn/reverse/delete-reverse-zone";
import { DeleteReverseZone$Params } from "../fn/reverse/delete-reverse-zone";
import { exportReverseRecords } from "../fn/reverse/export-reverse-records";
import { ExportReverseRecords$Params } from "../fn/reverse/export-reverse-records";
import { getPossiblePrefixes } from "../fn/reverse/get-possible-prefixes";
import { GetPossiblePrefixes$Params } from "../fn/reverse/get-possible-prefixes";
import { readReverseRecord } from "../fn/reverse/read-reverse-record";
import { ReadReverseRecord$Params } from "../fn/reverse/read-reverse-record";
import { readReverseRecords } from "../fn/reverse/read-reverse-records";
import { ReadReverseRecords$Params } from "../fn/reverse/read-reverse-records";
import { readReverseZone } from "../fn/reverse/read-reverse-zone";
import { ReadReverseZone$Params } from "../fn/reverse/read-reverse-zone";
import { readReverseZones } from "../fn/reverse/read-reverse-zones";
import { ReadReverseZones$Params } from "../fn/reverse/read-reverse-zones";
import { RecordsUpdateStatus } from "../models/records-update-status";
import { ReverseRecordRead } from "../models/reverse-record-read";
import { ReverseRecordSearch } from "../models/reverse-record-search";
import { ReverseZoneExternalRead } from "../models/reverse-zone-external-read";
import { ReverseZonePrimaryRead } from "../models/reverse-zone-primary-read";
import { ReverseZonePrimaryWithRecordsRead } from "../models/reverse-zone-primary-with-records-read";
import { ReverseZoneReadMinimal } from "../models/reverse-zone-read-minimal";
import { searchReverseRecords } from "../fn/reverse/search-reverse-records";
import { SearchReverseRecords$Params } from "../fn/reverse/search-reverse-records";
import { Status } from "../models/status";
import { updateReverseRecords } from "../fn/reverse/update-reverse-records";
import { UpdateReverseRecords$Params } from "../fn/reverse/update-reverse-records";
import { updateReverseZone } from "../fn/reverse/update-reverse-zone";
import { UpdateReverseZone$Params } from "../fn/reverse/update-reverse-zone";

@Injectable({ providedIn: "root" })
export class ReverseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPossiblePrefixes()` */
  static readonly GetPossiblePrefixesPath =
    "/surfdomeinen/organisations/{organisation_id}/reverse/subzones/{prefix}/{prefix_length}/{subzone_prefix_length}";

  /**
   * Get Possible Prefixes.
   *
   * Return a list of possible reverse zones for the given IPv4 prefix, excluding ones that are already present for this customer. The difference between the lengths should not be higher than 8 to prevent long lists.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPossiblePrefixes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossiblePrefixes$Response(
    params: GetPossiblePrefixes$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<string>>> {
    return getPossiblePrefixes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Possible Prefixes.
   *
   * Return a list of possible reverse zones for the given IPv4 prefix, excluding ones that are already present for this customer. The difference between the lengths should not be higher than 8 to prevent long lists.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPossiblePrefixes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossiblePrefixes(params: GetPossiblePrefixes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getPossiblePrefixes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body),
    );
  }

  /** Path part for operation `readReverseZones()` */
  static readonly ReadReverseZonesPath = "/surfdomeinen/organisations/{organisation_id}/reverse/";

  /**
   * Read Reverse Zones.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readReverseZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseZones$Response(
    params: ReadReverseZones$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ReverseZoneReadMinimal>>> {
    return readReverseZones(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Reverse Zones.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readReverseZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseZones(params: ReadReverseZones$Params, context?: HttpContext): Observable<Array<ReverseZoneReadMinimal>> {
    return this.readReverseZones$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReverseZoneReadMinimal>>): Array<ReverseZoneReadMinimal> => r.body),
    );
  }

  /** Path part for operation `createReverseZone()` */
  static readonly CreateReverseZonePath = "/surfdomeinen/organisations/{organisation_id}/reverse/";

  /**
   * Create Reverse Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createReverseZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createReverseZone$Response(
    params: CreateReverseZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status>> {
    return createReverseZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Reverse Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createReverseZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createReverseZone(
    params: CreateReverseZone$Params,
    context?: HttpContext,
  ): Observable<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status> {
    return this.createReverseZone$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status>,
        ): (ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status => r.body,
      ),
    );
  }

  /** Path part for operation `searchReverseRecords()` */
  static readonly SearchReverseRecordsPath = "/surfdomeinen/organisations/{organisation_id}/reverse/search_records";

  /**
   * Search Reverse Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchReverseRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchReverseRecords$Response(
    params: SearchReverseRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ReverseRecordSearch>>> {
    return searchReverseRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Reverse Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchReverseRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchReverseRecords(
    params: SearchReverseRecords$Params,
    context?: HttpContext,
  ): Observable<Array<ReverseRecordSearch>> {
    return this.searchReverseRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReverseRecordSearch>>): Array<ReverseRecordSearch> => r.body),
    );
  }

  /** Path part for operation `readReverseZone()` */
  static readonly ReadReverseZonePath = "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}";

  /**
   * Read Reverse Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readReverseZone()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseZone$Response(
    params: ReadReverseZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ReverseZonePrimaryWithRecordsRead | ReverseZoneExternalRead>> {
    return readReverseZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Reverse Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readReverseZone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseZone(
    params: ReadReverseZone$Params,
    context?: HttpContext,
  ): Observable<ReverseZonePrimaryWithRecordsRead | ReverseZoneExternalRead> {
    return this.readReverseZone$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<ReverseZonePrimaryWithRecordsRead | ReverseZoneExternalRead>,
        ): ReverseZonePrimaryWithRecordsRead | ReverseZoneExternalRead => r.body,
      ),
    );
  }

  /** Path part for operation `changeReverseZone()` */
  static readonly ChangeReverseZonePath = "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}";

  /**
   * Change Reverse Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeReverseZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeReverseZone$Response(
    params: ChangeReverseZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status>> {
    return changeReverseZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Change Reverse Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeReverseZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeReverseZone(
    params: ChangeReverseZone$Params,
    context?: HttpContext,
  ): Observable<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status> {
    return this.changeReverseZone$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status>,
        ): (ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status => r.body,
      ),
    );
  }

  /** Path part for operation `deleteReverseZone()` */
  static readonly DeleteReverseZonePath = "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}";

  /**
   * Delete Reverse Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReverseZone()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReverseZone$Response(
    params: DeleteReverseZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Status>> {
    return deleteReverseZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Reverse Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteReverseZone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReverseZone(params: DeleteReverseZone$Params, context?: HttpContext): Observable<Status> {
    return this.deleteReverseZone$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body),
    );
  }

  /** Path part for operation `updateReverseZone()` */
  static readonly UpdateReverseZonePath = "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}";

  /**
   * Update Reverse Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReverseZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReverseZone$Response(
    params: UpdateReverseZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status>> {
    return updateReverseZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Reverse Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReverseZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReverseZone(
    params: UpdateReverseZone$Params,
    context?: HttpContext,
  ): Observable<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status> {
    return this.updateReverseZone$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<(ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status>,
        ): (ReverseZonePrimaryRead | ReverseZoneExternalRead) | Status => r.body,
      ),
    );
  }

  /** Path part for operation `readReverseRecords()` */
  static readonly ReadReverseRecordsPath =
    "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}/records/";

  /**
   * Read Reverse Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readReverseRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseRecords$Response(
    params: ReadReverseRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<ReverseRecordRead>>> {
    return readReverseRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Reverse Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readReverseRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseRecords(params: ReadReverseRecords$Params, context?: HttpContext): Observable<Array<ReverseRecordRead>> {
    return this.readReverseRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReverseRecordRead>>): Array<ReverseRecordRead> => r.body),
    );
  }

  /** Path part for operation `updateReverseRecords()` */
  static readonly UpdateReverseRecordsPath =
    "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}/records/";

  /**
   * Update Reverse Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReverseRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReverseRecords$Response(
    params: UpdateReverseRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RecordsUpdateStatus>> {
    return updateReverseRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Reverse Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReverseRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReverseRecords(params: UpdateReverseRecords$Params, context?: HttpContext): Observable<RecordsUpdateStatus> {
    return this.updateReverseRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordsUpdateStatus>): RecordsUpdateStatus => r.body),
    );
  }

  /** Path part for operation `exportReverseRecords()` */
  static readonly ExportReverseRecordsPath =
    "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}/records/export";

  /**
   * Export Reverse Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportReverseRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportReverseRecords$Response(
    params: ExportReverseRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<any>> {
    return exportReverseRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Export Reverse Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportReverseRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportReverseRecords(params: ExportReverseRecords$Params, context?: HttpContext): Observable<any> {
    return this.exportReverseRecords$Response(params, context).pipe(map((r: StrictHttpResponse<any>): any => r.body));
  }

  /** Path part for operation `readReverseRecord()` */
  static readonly ReadReverseRecordPath =
    "/surfdomeinen/organisations/{organisation_id}/reverse/{reverse_zone_id}/records/{reverse_record_id}";

  /**
   * Read Reverse Record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readReverseRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseRecord$Response(
    params: ReadReverseRecord$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ReverseRecordRead>> {
    return readReverseRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Reverse Record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readReverseRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readReverseRecord(params: ReadReverseRecord$Params, context?: HttpContext): Observable<ReverseRecordRead> {
    return this.readReverseRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReverseRecordRead>): ReverseRecordRead => r.body),
    );
  }
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { RecordType } from "../../models/record-type";
import { ReverseRecordSearch } from "../../models/reverse-record-search";

export interface SearchReverseRecords$Params {
  organisation_id: string;

  /**
   * The sort will accept parameters like `col` or `col:desc` and will split on the `:`. If it does not find a `:` it will sort ascending on that column. Sortable columns: id, reverse_zone_id, name, type, ttl, value, conflict, is_active, is_locked
   */
  sort?: Array<string> | null;
  name?: string | null;
  record_type?: RecordType | null;
  value?: string | null;
  is_active?: boolean | null;
  is_locked?: boolean | null;
  token?: string | null;
  offset?: number;
  limit?: number;
}

export function searchReverseRecords(
  http: HttpClient,
  rootUrl: string,
  params: SearchReverseRecords$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<ReverseRecordSearch>>> {
  const rb = new RequestBuilder(rootUrl, searchReverseRecords.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("sort", params.sort, {});
    rb.query("name", params.name, {});
    rb.query("record_type", params.record_type, {});
    rb.query("value", params.value, {});
    rb.query("is_active", params.is_active, {});
    rb.query("is_locked", params.is_locked, {});
    rb.query("token", params.token, {});
    rb.query("offset", params.offset, {});
    rb.query("limit", params.limit, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ReverseRecordSearch>>;
    }),
  );
}

searchReverseRecords.PATH = "/surfdomeinen/organisations/{organisation_id}/reverse/search_records";

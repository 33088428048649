/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { DomainZone } from "../models/domain-zone";
import { getOverviewCombined } from "../fn/overview/get-overview-combined";
import { GetOverviewCombined$Params } from "../fn/overview/get-overview-combined";

@Injectable({ providedIn: "root" })
export class OverviewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOverviewCombined()` */
  static readonly GetOverviewCombinedPath = "/surfdomeinen/organisations/{organisation_id}/overview/combined";

  /**
   * Get Overview Combined.
   *
   * Combined list of all zones and domains
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverviewCombined()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewCombined$Response(
    params: GetOverviewCombined$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<DomainZone>>> {
    return getOverviewCombined(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Overview Combined.
   *
   * Combined list of all zones and domains
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOverviewCombined$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewCombined(params: GetOverviewCombined$Params, context?: HttpContext): Observable<Array<DomainZone>> {
    return this.getOverviewCombined$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DomainZone>>): Array<DomainZone> => r.body),
    );
  }
}

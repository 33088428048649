/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { HistoryRead } from "../models/history-read";
import { readHistory } from "../fn/history/read-history";
import { ReadHistory$Params } from "../fn/history/read-history";

@Injectable({ providedIn: "root" })
export class HistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readHistory()` */
  static readonly ReadHistoryPath = "/surfdomeinen/organisations/{organisation_id}/history/";

  /**
   * Read History.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  readHistory$Response(
    params: ReadHistory$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<HistoryRead>>> {
    return readHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Read History.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readHistory(params: ReadHistory$Params, context?: HttpContext): Observable<Array<HistoryRead>> {
    return this.readHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HistoryRead>>): Array<HistoryRead> => r.body),
    );
  }
}

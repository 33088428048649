/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { disableDnssec } from "../fn/dnssec/disable-dnssec";
import { DisableDnssec$Params } from "../fn/dnssec/disable-dnssec";
import { DnssecKeyReadDetails } from "../models/dnssec-key-read-details";
import { enableDnssec } from "../fn/dnssec/enable-dnssec";
import { EnableDnssec$Params } from "../fn/dnssec/enable-dnssec";
import { getDomainDnssecKeys } from "../fn/dnssec/get-domain-dnssec-keys";
import { GetDomainDnssecKeys$Params } from "../fn/dnssec/get-domain-dnssec-keys";
import { setDomainDnssecKeys } from "../fn/dnssec/set-domain-dnssec-keys";
import { SetDomainDnssecKeys$Params } from "../fn/dnssec/set-domain-dnssec-keys";
import { Status } from "../models/status";

@Injectable({ providedIn: "root" })
export class DnssecService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDomainDnssecKeys()` */
  static readonly GetDomainDnssecKeysPath = "/surfdomeinen/organisations/{organisation_id}/dnssec/domains/{domain_id}";

  /**
   * Get Domain Dnssec Keys.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDomainDnssecKeys()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDomainDnssecKeys$Response(
    params: GetDomainDnssecKeys$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<DnssecKeyReadDetails>>> {
    return getDomainDnssecKeys(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Domain Dnssec Keys.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDomainDnssecKeys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDomainDnssecKeys(
    params: GetDomainDnssecKeys$Params,
    context?: HttpContext,
  ): Observable<Array<DnssecKeyReadDetails>> {
    return this.getDomainDnssecKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DnssecKeyReadDetails>>): Array<DnssecKeyReadDetails> => r.body),
    );
  }

  /** Path part for operation `setDomainDnssecKeys()` */
  static readonly SetDomainDnssecKeysPath = "/surfdomeinen/organisations/{organisation_id}/dnssec/domains/{domain_id}";

  /**
   * Set Domain Dnssec Keys.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDomainDnssecKeys()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDomainDnssecKeys$Response(
    params: SetDomainDnssecKeys$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Status>> {
    return setDomainDnssecKeys(this.http, this.rootUrl, params, context);
  }

  /**
   * Set Domain Dnssec Keys.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setDomainDnssecKeys$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDomainDnssecKeys(params: SetDomainDnssecKeys$Params, context?: HttpContext): Observable<Status> {
    return this.setDomainDnssecKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status>): Status => r.body),
    );
  }

  /** Path part for operation `enableDnssec()` */
  static readonly EnableDnssecPath = "/surfdomeinen/organisations/{organisation_id}/dnssec/zones/{zone_id}/enable";

  /**
   * Enable Dnssec.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableDnssec()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableDnssec$Response(params: EnableDnssec$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return enableDnssec(this.http, this.rootUrl, params, context);
  }

  /**
   * Enable Dnssec.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableDnssec$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableDnssec(params: EnableDnssec$Params, context?: HttpContext): Observable<Status> {
    return this.enableDnssec$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }

  /** Path part for operation `disableDnssec()` */
  static readonly DisableDnssecPath = "/surfdomeinen/organisations/{organisation_id}/dnssec/zones/{zone_id}/disable";

  /**
   * Disable Dnssec.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableDnssec()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableDnssec$Response(params: DisableDnssec$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return disableDnssec(this.http, this.rootUrl, params, context);
  }

  /**
   * Disable Dnssec.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disableDnssec$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableDnssec(params: DisableDnssec$Params, context?: HttpContext): Observable<Status> {
    return this.disableDnssec$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }
}
